import BigNumber from 'bignumber.js';
import { AppChain, fromWei } from 'helpers';
import { PairData, PlaynityTokenName, TokenData } from 'models';
import { Action, Dispatch } from 'redux';
import { appActions } from 'store';
import { Contract } from 'web3-eth-contract';

import { LCDClient } from '@terra-money/terra.js';

export const getBalance = (
  tokenData: TokenData,
  connectedAddress: string,
  terra?: LCDClient
): ((dispatch: Dispatch<Action>) => void) => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return getEvmBalance(tokenData, connectedAddress);
    case AppChain.Terra:
      return getTerraBalance(tokenData, connectedAddress, terra);
  }
};

export const getEvmBalance = (
  tokenData: TokenData,
  connectedAddress: string
): ((dispatch: Dispatch<Action>) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    const getData = async (tokenContract: Contract) => {
      const balance: string = await tokenContract.methods
        .balanceOf(connectedAddress)
        .call();

      return balance;
    };

    try {
      const balance = await getData(tokenData.tokenContract);
      const fromWeiBalance = fromWei(balance, tokenData.decimals);

      switch (tokenData.name) {
        case PlaynityTokenName.Playnity:
          dispatch(appActions.setBalance(fromWeiBalance));
          break;

        case PlaynityTokenName.LPPlaynity:
          dispatch(appActions.setBalanceLP(fromWeiBalance));
          break;

        case PlaynityTokenName.Stablecoin:
          dispatch(appActions.setBalanceStablecoin(fromWeiBalance));
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getTerraBalance = (
  tokenData: TokenData,
  connectedAddress: string,
  terra?: LCDClient
): ((dispatch: Dispatch<Action>) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    const getData = async () => {
      const response: { balance: string } = await terra.wasm.contractQuery(
        tokenData.contractAddress,
        {
          balance: { address: connectedAddress },
        }
      );

      return response.balance;
    };

    try {
      const balance = await getData();
      const fromWeiBalance = fromWei(balance, tokenData.decimals);

      switch (tokenData.name) {
        case PlaynityTokenName.Playnity:
          dispatch(appActions.setBalance(fromWeiBalance));
          break;

        case PlaynityTokenName.LPPlaynity:
          dispatch(appActions.setBalanceLP(fromWeiBalance));
          break;

        case PlaynityTokenName.Stablecoin:
          dispatch(appActions.setBalanceStablecoin(fromWeiBalance));
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getTokenPrice = (
  tokenData: TokenData,
  stableTokenData: TokenData,
  lpPairData: PairData,
  terra?: LCDClient
): ((dispatch: Dispatch<Action>) => void) => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return getEvmTokenPrice(
        tokenData,
        stableTokenData,
        lpPairData.pairContract
      );
    case AppChain.Terra:
      return getTerraTokenPrice(tokenData, lpPairData.contractAddress, terra);
  }
};

export const getEvmTokenPrice = (
  tokenData: TokenData, // PLY Token
  stableTokenData: TokenData,
  lpPairContract: Contract
): ((dispatch: Dispatch<Action>) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const token0Address: string = await lpPairContract.methods
        .token0()
        .call();

      const reserves = await lpPairContract.methods.getReserves().call();
      let reserve0 = 0;
      let reserve1 = 0;
      // ? First token in pair is PLY token, needed for testnet pancake
      const isFirstTokenInPairPLY =
        token0Address.toLocaleLowerCase() ===
        tokenData.tokenContract.options.address.toLocaleLowerCase();

      if (isFirstTokenInPairPLY) {
        reserve0 = reserves._reserve0;
        reserve1 = reserves._reserve1;
      } else {
        reserve0 = reserves._reserve1;
        reserve1 = reserves._reserve0;
      }

      const tokenPriceBN = new BigNumber(reserve1)
        .div(new BigNumber(reserve0))
        .div(
          new BigNumber(10).pow(stableTokenData.decimals - tokenData.decimals)
        );
      const tokenPrice = tokenPriceBN.toString(10);

      dispatch(appActions.setTokenPrice(tokenPrice));
    } catch (e) {
      console.log(e);
    }
  };
};

export const getTerraTokenPrice = (
  tokenData: TokenData,
  lpPairContractAddress: string,
  terra: LCDClient
): ((dispatch: Dispatch<Action>) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    const getData = async () => {
      const msg = {
        simulation: {
          offer_asset: {
            amount: '1000000',
            info: { token: { contract_addr: tokenData.contractAddress } },
          },
        },
      };
      const response: {
        commission_amount: string;
        return_amount: string;
        spread_amount: string;
      } = await terra.wasm.contractQuery(lpPairContractAddress, msg);

      return new BigNumber(response.return_amount);
    };

    try {
      const tokenPriceWei = await getData();
      const tokenPrice = fromWei(tokenPriceWei, tokenData.decimals);

      dispatch(appActions.setTokenPrice(tokenPrice));
    } catch (e) {
      console.log(e);
    }
  };
};

export const getLPTokenPrice = (
  tokensInLPPair: string,
  tokenPrice: string,
  totalLP: string
): ((dispatch: Dispatch<Action>) => void) => {
  return (dispatch: Dispatch<Action>): void => {
    try {
      const tokensInLPPairBN = new BigNumber(tokensInLPPair);
      const tokenPriceBN = new BigNumber(tokenPrice);
      const totalLPBN = new BigNumber(totalLP);
      const LPTokenPrice = totalLPBN.isGreaterThan(0)
        ? tokensInLPPairBN
            .times(2)
            .times(tokenPriceBN)
            .div(totalLPBN)
            .toString(10)
        : '0';

      dispatch(appActions.setLPTokenPrice(LPTokenPrice));
    } catch (e) {
      console.log(e);
    }
  };
};

export const getStableTokenPrice = (
  stableTokenData: TokenData,
  stablePairData: PairData,
  terra?: LCDClient
): ((dispatch: Dispatch<Action>) => void) => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return getEvmStableTokenPrice();
    case AppChain.Terra:
      return getTerraStableTokenPrice(
        stableTokenData,
        stablePairData.contractAddress,
        terra
      );
  }
};

export const getEvmStableTokenPrice = (): ((
  dispatch: Dispatch<Action>
) => void) => {
  return (dispatch: Dispatch<Action>): void => {
    const stableTokenPrice = '1';

    dispatch(appActions.setStableTokenPrice(stableTokenPrice));
  };
};

export const getTerraStableTokenPrice = (
  stableTokenData: TokenData,
  stablePairContractAddress: string,
  terra: LCDClient
): ((dispatch: Dispatch<Action>) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    const getData = async () => {
      const msg = {
        simulation: {
          offer_asset: {
            amount: '1000000',
            info: { token: { contract_addr: stableTokenData.contractAddress } },
          },
        },
      };
      const response: {
        commission_amount: string;
        return_amount: string;
        spread_amount: string;
      } = await terra.wasm.contractQuery(stablePairContractAddress, msg);

      return new BigNumber(response.return_amount);
    };

    try {
      const stableTokenPriceWei = await getData();
      const stableTokenPrice = fromWei(
        stableTokenPriceWei,
        stableTokenData.decimals
      );

      dispatch(appActions.setStableTokenPrice(stableTokenPrice));
    } catch (e) {
      console.log(e);
    }
  };
};
