import { AppChain, canStake, getContract, playnityConfig } from 'helpers';
import {
  StakingPoolConfig,
  StakingPoolData,
  StakingPoolItemConfig,
  StakingPoolItemData,
} from 'models';
import { Dispatch } from 'react';
import { stakingActions } from 'store';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

import { Action } from '@reduxjs/toolkit';
import { LCDClient } from '@terra-money/terra.js';

export const getStakingPoolsData = (
  networkId: string,
  web3: Web3,
  connectedAddress: string,
  terra?: LCDClient
): ((dispatch: Dispatch<Action>) => void) => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return getEvmStakingPoolsData(networkId, web3, connectedAddress);
    case AppChain.Terra:
      return getTerraStakingPoolsData(networkId, connectedAddress, terra);
  }
};

const getEvmStakingPoolsData = (
  networkId: string,
  web3: Web3,
  connectedAddress: string
): ((dispatch: Dispatch<Action>) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const stakingPoolsData: StakingPoolData[] = await Promise.all(
        playnityConfig.staking?.pools.map(
          async (stakingPool: StakingPoolConfig) => {
            const stakingPoolItems: StakingPoolItemData[] = await Promise.all(
              stakingPool.poolItems.map(
                async (
                  stakingPoolItem: StakingPoolItemConfig
                ): Promise<StakingPoolItemData> => {
                  const stakingContractInstance: Contract = getContract(
                    web3,
                    stakingPoolItem.stakingContractJSON,
                    networkId
                  );
                  const addressCanStake = stakingPoolItem.hasWhitelist
                    ? await canStake(connectedAddress, stakingContractInstance)
                    : true;

                  return {
                    poolItemName: stakingPoolItem.poolItemName,
                    poolItemtype: stakingPoolItem.poolItemtype,
                    stakingPoolItemContract: stakingContractInstance,
                    lockPeriod: stakingPoolItem.lockPeriod,
                    addressCanStake,
                  };
                }
              )
            );

            return {
              poolType: stakingPool.poolType,
              poolSubtype: stakingPool.poolSubtype,
              poolName: stakingPool.poolName,
              poolDescription: stakingPool.poolDescription,
              poolItems: stakingPoolItems,
            };
          }
        )
      );

      dispatch(stakingActions.setStakingPoolsData(stakingPoolsData));
    } catch (e) {
      console.log(e);
    }
  };
};

const getTerraStakingPoolsData = (
  networkId: string,
  connectedAddress: string,
  terra: LCDClient
): ((dispatch: Dispatch<Action>) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const stakingPoolsData: StakingPoolData[] = await Promise.all(
        playnityConfig.staking?.pools.map(
          async (stakingPool: StakingPoolConfig) => {
            const stakingPoolItems: StakingPoolItemData[] = await Promise.all(
              stakingPool.poolItems.map(
                async (
                  stakingPoolItem: StakingPoolItemConfig
                ): Promise<StakingPoolItemData> => {
                  const contractAddress =
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (stakingPoolItem.stakingContractJSON.networks as any)[
                      networkId
                    ]?.address;

                  const addressCanStake = stakingPoolItem.hasWhitelist
                    ? await canStake(
                        connectedAddress,
                        null,
                        contractAddress,
                        terra
                      )
                    : true;

                  return {
                    poolItemName: stakingPoolItem.poolItemName,
                    poolItemtype: stakingPoolItem.poolItemtype,
                    stakingPoolItemContract: null,
                    contractAddress,
                    lockPeriod: stakingPoolItem.lockPeriod,
                    addressCanStake,
                  };
                }
              )
            );

            return {
              poolType: stakingPool.poolType,
              poolSubtype: stakingPool.poolSubtype,
              poolName: stakingPool.poolName,
              poolDescription: stakingPool.poolDescription,
              poolItems: stakingPoolItems,
            };
          }
        )
      );

      dispatch(stakingActions.setStakingPoolsData(stakingPoolsData));
    } catch (e) {
      console.log(e);
    }
  };
};
