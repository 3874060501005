import BigNumber from 'bignumber.js';
import { ButtonWithSpinner, Message, PercentageButtons } from 'components';
import { MessageText, MessageType, numbersOnly, playnityConfig } from 'helpers';
import {
  StakingPoolItemData,
  StakingPoolItemStakingData,
  TokenData,
} from 'models';
import React from 'react';
import { Tab } from 'react-bootstrap';

interface StakingPoolItemStakeTabContentProps {
  loading: boolean;
  balance: string;
  stakingValue: string;
  staking: StakingPoolItemStakingData;
  stakingPoolItemData: StakingPoolItemData;
  stakingTokenData: TokenData;
  onApprove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onStake: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onStakeValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onApplyPercentage: (percentage: number, value: string) => void;
}

export const StakingPoolItemStakeTabContent: React.FC<
  StakingPoolItemStakeTabContentProps
> = ({
  loading,
  balance,
  stakingValue,
  staking,
  stakingPoolItemData,
  stakingTokenData,
  onApprove,
  onStake,
  onStakeValueChange,
  onApplyPercentage,
}) => {
  const stakingValueBN = new BigNumber(stakingValue);
  const balanceBN = new BigNumber(balance);
  const stakingAllowanceBN = new BigNumber(staking.stakingAllowance);
  const lockedStakesExceeded =
    stakingPoolItemData.lockPeriod &&
    staking.bondedTokensData?.length >=
      playnityConfig.maxLockedStakesPerAccount;
  const maxStakeReached =
    +staking?.maxStake &&
    new BigNumber(staking.maxStake)
      .minus(new BigNumber(staking.stakedTokens).plus(stakingValueBN))
      .isLessThan(0);
  const stakeButtonDisabled =
    +stakingValue == 0 ||
    stakingValueBN.isGreaterThan(balanceBN) ||
    lockedStakesExceeded ||
    maxStakeReached;

  const button = stakingValueBN.isGreaterThan(stakingAllowanceBN) ? (
    <ButtonWithSpinner
      text="Approve"
      classes="btn-style-two approve-section-button"
      onClick={onApprove}
      loading={loading}
    />
  ) : (
    <ButtonWithSpinner
      text="Stake"
      classes="btn-style-two"
      onClick={onStake}
      disabled={stakeButtonDisabled}
      loading={loading}
    />
  );

  return (
    <Tab.Pane eventKey="stake" unmountOnExit={true} mountOnEnter={true}>
      <div className="tab-content">
        {+staking.stakingAllowance == 0 && (
          <div className="approve-section">
            <div>{MessageText.Approve}</div>
            <ButtonWithSpinner
              text="Approve"
              classes="btn-style-two approve-section-button"
              onClick={onApprove}
              disabled={loading}
              loading={loading}
            />
          </div>
        )}
        {+staking.stakingAllowance != 0 && (
          <div className="stake-section">
            <div className="stake-section-description">
              {staking.unbondingPeriod !== undefined ? (
                <span>
                  Unbonding period - {+staking.unbondingPeriod / 86400} days
                </span>
              ) : (
                <span>
                  Staked tokens will be locked for a period of{' '}
                  {stakingPoolItemData.lockPeriod} months. By staking in this
                  pool you acknowledge that you will not be able to withdraw the
                  staked tokens by any means before this locking period elapses.
                </span>
              )}
            </div>
            <div className="stake-section-actions">
              <div className="left">
                <div className="playnity-input-wrapper">
                  <input
                    className="playnity-input"
                    placeholder="0.00"
                    onChange={onStakeValueChange}
                    value={stakingValue}
                    onKeyPress={numbersOnly}
                    disabled={balanceBN.isEqualTo(0)}
                  />
                </div>
                {button}
              </div>
              <div className="right">
                <PercentageButtons
                  applyPercentage={(percentage) =>
                    onApplyPercentage(percentage, balance)
                  }
                  disabled={balanceBN.isEqualTo(0) || loading}
                />
              </div>
            </div>
          </div>
        )}
        {stakingValueBN.isGreaterThan(stakingAllowanceBN) ? (
          <Message
            messageType={MessageType.Error}
            descriptionText={MessageText.ValueGreaterThanApproved}
          />
        ) : (
          ''
        )}
      </div>
      {!+balance && !!+staking.stakingAllowance && (
        <Message
          descriptionText={MessageText.NoTokens}
          messageType={MessageType.Primary}
        />
      )}
      {lockedStakesExceeded && (
        <Message
          descriptionText={`You cannot have more than ${playnityConfig.maxLockedStakesPerAccount} active stakes from one address.`}
          messageType={MessageType.Primary}
        />
      )}
      {maxStakeReached ? (
        <Message
          descriptionText={`You cannot stake more than ${staking.maxStake} ${stakingTokenData.symbol}`}
          messageType={MessageType.Primary}
        />
      ) : (
        ''
      )}
    </Tab.Pane>
  );
};
