import { AppChain } from 'helpers';

import { isChain } from './connection.helper';

export const applyMask = (text: string): string => {
  return text.substr(0, 6) + '**' + text.substr(-4);
};

export const roundNumber = (value: string, precision = 6): string => {
  if (isChain(AppChain.Bsc) && precision == 6) {
    precision = 8;
  } else if (isChain(AppChain.Ethereum)) {
    precision = 12;
  }

  const pointIndex = value.lastIndexOf('.');

  return pointIndex > 0 ? value.slice(0, pointIndex + precision + 1) : value;
};

export const isCorrectValue = (value: string, decimals: number): boolean => {
  let decimalsCount = 0;

  if (value?.indexOf('.') > -1) {
    decimalsCount = value.slice(value.indexOf('.') + 1, value.length).length;
  }

  return decimalsCount <= decimals;
};

export const asyncSleep = function (milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const copyToClipboard = (value: string) => {
  navigator.clipboard.writeText(value);
};

export const parseCurrency = (
  value: string,
  maximumFractionDigits?: number
) => {
  return parseFloat(value).toLocaleString('en', {
    style: 'currency',
    currency: 'USD',
    ...(maximumFractionDigits && { maximumFractionDigits }),
  });
};
