import { Footer, Header } from 'components';
import React, { PropsWithChildren } from 'react';

interface LayoutProps {
  connectWallet: () => void;
  disconnectWallet: () => void;
}

export const Layout: React.FC<PropsWithChildren<LayoutProps>> = ({
  connectWallet,
  disconnectWallet,
  children,
}) => {
  return (
    <div className="app-layout">
      <Header
        connectWallet={connectWallet}
        disconnectWallet={disconnectWallet}
      />

      <div className="app-content">
        <div className="app-wrapper">
          <div className="container">
            <div className="row justify-content-center relative">
              <div className="col-xl-8 col-lg-9 app-inner">{children}</div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
