import { AppChain, playnityConfig, terraExecuteContract, toWei } from 'helpers';
import { StakingPoolItemData } from 'models';

import { LCDClient, MsgExecuteContract, TxInfo } from '@terra-money/terra.js';

export const postWithdrawInstant = async (
  stakingPoolItemData: StakingPoolItemData,
  availableForWithdrawal: string,
  decimals: number,
  connectedAddress: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  terraConnectedWallet: any,
  terra: LCDClient
): Promise<any> => {
  const availableForWithdrawalWei = toWei(availableForWithdrawal, decimals);

  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return await postEvmWithdrawInstant(
        stakingPoolItemData,
        availableForWithdrawalWei,
        connectedAddress
      );

    case AppChain.Terra:
      return await postTerraWithdrawInstant(
        stakingPoolItemData,
        availableForWithdrawalWei,
        connectedAddress,
        terraConnectedWallet,
        terra
      );

    default:
      throw new Error('Unsupported Chain');
  }
};

const postEvmWithdrawInstant = async (
  stakingPoolItemData: StakingPoolItemData,
  availableForWithdrawal: string,
  connectedAddress: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  return await stakingPoolItemData.stakingPoolItemContract.methods
    .withdrawInstantWithPenalty(availableForWithdrawal)
    .send({
      from: connectedAddress,
    });
};

const postTerraWithdrawInstant = async (
  stakingPoolItemData: StakingPoolItemData,
  availableForWithdrawal: string,
  connectedAddress: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  terraConnectedWallet: any,
  terra: LCDClient
): Promise<TxInfo> => {
  const withdrawMsg: MsgExecuteContract = new MsgExecuteContract(
    connectedAddress,
    stakingPoolItemData.contractAddress,
    {
      unbond: {
        amount: availableForWithdrawal,
      },
    },
    {
      uusd: playnityConfig.playnityFee,
    }
  );
  const tx: TxInfo = await terraExecuteContract(
    terraConnectedWallet,
    terra,
    withdrawMsg,
    connectedAddress
  );
  return tx;
};

export const postWithdraw = async (
  stakingPoolItemData: StakingPoolItemData,
  withdrawValue: string,
  decimals: number,
  connectedAddress: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  terraConnectedWallet: any,
  terra: LCDClient,
  unbondingPeriod?: string
): Promise<any> => {
  const withdrawValueWei = toWei(withdrawValue, decimals);

  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return await postEvmWithdraw(
        stakingPoolItemData,
        withdrawValueWei,
        connectedAddress
      );

    case AppChain.Terra:
      return await postTerraWithdraw(
        stakingPoolItemData,
        withdrawValueWei,
        connectedAddress,
        terraConnectedWallet,
        terra,
        unbondingPeriod
      );

    default:
      console.error('Unsupported chain');
  }
};

const postEvmWithdraw = async (
  stakingPoolItemData: StakingPoolItemData,
  withdrawValue: string,
  connectedAddress: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  return await stakingPoolItemData.stakingPoolItemContract.methods
    .withdraw(withdrawValue)
    .send({
      from: connectedAddress,
    });
};

const postTerraWithdraw = async (
  stakingPoolItemData: StakingPoolItemData,
  withdrawValue: string,
  connectedAddress: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  terraConnectedWallet: any,
  terra: LCDClient,
  unbondingPeriod: string
): Promise<TxInfo> => {
  let withdrawMsg: MsgExecuteContract;
  if (unbondingPeriod !== undefined) {
    withdrawMsg = new MsgExecuteContract(
      connectedAddress,
      stakingPoolItemData.contractAddress,
      {
        unbond: {
          amount: withdrawValue,
        },
      },
      {
        uusd: playnityConfig.playnityFee,
      }
    );
  } else {
    withdrawMsg = new MsgExecuteContract(
      connectedAddress,
      stakingPoolItemData.contractAddress,
      {
        instant_unbond: {
          amount: withdrawValue,
        },
      },
      {
        uusd: playnityConfig.playnityFee,
      }
    );
  }
  const tx: TxInfo = await terraExecuteContract(
    terraConnectedWallet,
    terra,
    withdrawMsg,
    connectedAddress
  );
  return tx;
};
