import { ScrollToTop } from 'components';
import {
  ConditionalTerraWalletProvider,
} from 'components/AppWrappers/AppWrapper';
import { avaliableTerraNetworks } from 'config';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { store } from 'store';

import { WalletProvider } from '@starterra/starterra-tool-dapp';

import App from './App';
import reportWebVitals from './reportWebVitals';
// TODO: Move to assets - separate MR
import './styles/index.scss';

// Extend window interface
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ethereum: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    web3: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    BinanceChain: any;
  }
}

ReactDOM.render(
  <Provider store={store}>
    <ConditionalTerraWalletProvider
      wrapper={(children: React.ReactNode) => (
        <WalletProvider
          defaultNetwork={avaliableTerraNetworks[0]}
          walletConnectChainIds={avaliableTerraNetworks}
        >
          {children}
        </WalletProvider>
      )}
    >
      <Router>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </Router>
    </ConditionalTerraWalletProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
