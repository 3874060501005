// import { AppChain, isChain } from 'helpers';
import { StakingPoolData, StakingPoolType, TokenData } from 'models';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  selectBalance,
  selectConnectedAddress,
  selectLPBalance,
  selectLPTokenData,
  selectStablecoinBalance,
  selectStableTokenData,
  selectStakingPools,
  selectTokenData,
} from 'store';

import StakingPool from './StakingPool';

// TODO: Remove in later stage
const modalAddresses: string[] = [
  // '0x3c747b91ABE9B73bC05A0a5CE8a84af971270334',
  // '0x03546e4A04A065C242Ada9995280D31D98694A01',
];

const StakingPools: React.FC = () => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const connectedAddress: string = useSelector(selectConnectedAddress);
  const balance: string = useSelector(selectBalance);
  const LPBalance: string = useSelector(selectLPBalance);
  const stablecoinBalance: string = useSelector(selectStablecoinBalance);
  const tokenData: TokenData = useSelector(selectTokenData);
  const LPTokenData: TokenData = useSelector(selectLPTokenData);
  const stableTokenData: TokenData = useSelector(selectStableTokenData);
  const stakingPoolsData: StakingPoolData[] = useSelector(selectStakingPools);

  useEffect(() => {
    if (connectedAddress && modalAddresses.includes(connectedAddress)) {
      setShowInfoModal(true);
    }
  }, [connectedAddress]);

  const getStakingTokenData = (stakingPoolData: StakingPoolData): TokenData => {
    switch (stakingPoolData.poolType) {
      case StakingPoolType.SA:
        return tokenData;
      case StakingPoolType.LP:
        return LPTokenData;
      case StakingPoolType.SC:
        return stableTokenData;
    }
  };

  const getPoolTokenBalance = (stakingPoolData: StakingPoolData): string => {
    switch (stakingPoolData.poolType) {
      case StakingPoolType.SA:
        return balance;

      case StakingPoolType.LP:
        return LPBalance;

      case StakingPoolType.SC:
        return stablecoinBalance;
    }
  };

  const stakingPools = stakingPoolsData?.map(
    (stakingPoolData: StakingPoolData, index: number) => {
      return (
        <StakingPool
          key={`staking-pool-component-${index}`}
          stakingPoolData={stakingPoolData}
          LPTokenData={LPTokenData}
          tokenData={tokenData}
          stablecoinTokenData={stableTokenData}
          stakingTokenData={getStakingTokenData(stakingPoolData)}
          balance={getPoolTokenBalance(stakingPoolData)}
        />
      );
    }
  );

  // TODO: Get symbol from stablecoin token contract
  // const getStablecoinSymbol = (): string => {
  //   switch (process.env.REACT_APP_CHAIN) {
  //     case AppChain.Bsc:
  //       return 'BUSD';
  //     case AppChain.Ethereum:
  //       return 'USDT';
  //     case AppChain.Terra:
  //       return 'UST';
  //   }
  // };

  // const SAStakingPlaceholder = (
  //   <>
  //     <div
  //       className="staking-pool single-asset-staking inactive"
  //       style={{ marginTop: '24px' }}
  //     >
  //       <div className="staking-pool-header">
  //         <span className="pool-type">
  //           {'//'} Single Asset Staking - PEX BONUS
  //         </span>
  //         PLY
  //         <OverlayTrigger
  //           overlay={
  //             // eslint-disable-next-line react/jsx-no-undef
  //             <Tooltip id="staking-pool-tooltip">
  //               Locked staking pools can be unlocked by gaining PEX points.
  //             </Tooltip>
  //           }
  //         >
  //           <i className="far fa-question-circle ml-8 pointer"></i>
  //         </OverlayTrigger>
  //       </div>
  //       <div className="staking-pool-item locked accordion">
  //         <div className="card">
  //           <div className="card-header">
  //             <div className="staking-pool-item-header">
  //               <div className="name">
  //                 PEX Bonus -<span className="name-icon">🥉</span>Bronze
  //               </div>
  //               {/* <div className="apy">est. APY 18%</div> */}
  //               <div className="total">TEMPORARILY UNAVAILABLE...</div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>

  //       <div className="staking-pool-item locked accordion">
  //         <div className="card">
  //           <div className="card-header">
  //             <div className="staking-pool-item-header">
  //               <div className="name">
  //                 PEX Bonus -<span className="name-icon">🥈</span>Silver
  //               </div>
  //               {/* <div className="apy">est. APY 27%</div> */}
  //               <div className="total">TEMPORARILY UNAVAILABLE...</div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>

  //       <div className="staking-pool-item locked accordion">
  //         <div className="card">
  //           <div className="card-header">
  //             <div className="staking-pool-item-header">
  //               <div className="name">
  //                 PEX Bonus -<span className="name-icon">🥇</span>Gold
  //               </div>
  //               {/* <div className="apy">est. APY 36%</div> */}
  //               <div className="total">TEMPORARILY UNAVAILABLE...</div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>

  //       <div className="staking-pool-item locked accordion">
  //         <div className="card">
  //           <div className="card-header">
  //             <div className="staking-pool-item-header">
  //               <div className="name">
  //                 PEX Bonus -<span className="name-icon">💎</span>Diamond
  //               </div>
  //               {/* <div className="apy">est. APY 45%</div> */}
  //               <div className="total">TEMPORARILY UNAVAILABLE...</div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );

  // const StablecoinStakingPlaceholder = (
  //   <>
  //     <div
  //       className="staking-pool stablecoin-staking inactive"
  //       style={{ marginTop: '24px' }}
  //     >
  //       <div className="staking-pool-header">
  //         <span className="pool-type">{'//'} Stablecoin Staking</span>{' '}
  //         {getStablecoinSymbol()}
  //         <OverlayTrigger
  //           overlay={
  //             <Tooltip id="staking-pool-tooltip">
  //               Locked staking pools can be unlocked by gaining PEX points.
  //             </Tooltip>
  //           }
  //         >
  //           <i className="far fa-question-circle ml-8 pointer"></i>
  //         </OverlayTrigger>
  //       </div>
  //     </div>
  //     <div
  //       className="staking-pool stablecoin-staking inactive"
  //       style={{ marginTop: '24px' }}
  //     >
  //       <div className="staking-pool-item locked accordion">
  //         <div className="card">
  //           <div className="card-header">
  //             <div className="staking-pool-item-header">
  //               <div className="name">
  //                 PEX Bonus -<span className="name-icon">🥉</span>Bronze
  //               </div>
  //               {/* <div className="apy">
  //                 est. APY 23%
  //                 <div className="apy-limit">limit $1,000*</div>
  //               </div> */}
  //               <div className="total">TEMPORARILY UNAVAILABLE...</div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>

  //       <div className="staking-pool-item locked accordion">
  //         <div className="card">
  //           <div className="card-header">
  //             <div className="staking-pool-item-header">
  //               <div className="name">
  //                 PEX Bonus -<span className="name-icon">🥈</span>Silver
  //               </div>
  //               {/* <div className="apy">
  //                 est. APY 26%
  //                 <div className="apy-limit">limit $3,000*</div>
  //               </div> */}
  //               <div className="total">TEMPORARILY UNAVAILABLE...</div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>

  //       <div className="staking-pool-item locked accordion">
  //         <div className="card">
  //           <div className="card-header">
  //             <div className="staking-pool-item-header">
  //               <div className="name">
  //                 PEX Bonus -<span className="name-icon">🥇</span>Gold
  //               </div>
  //               {/* <div className="apy">
  //                 est. APY 30%
  //                 <div className="apy-limit">limit $10,000*</div>
  //               </div> */}
  //               <div className="total">TEMPORARILY UNAVAILABLE...</div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>

  //       <div className="staking-pool-item locked accordion">
  //         <div className="card">
  //           <div className="card-header">
  //             <div className="staking-pool-item-header">
  //               <div className="name">
  //                 PEX Bonus -<span className="name-icon">💎</span>Diamond
  //               </div>
  //               {/* <div className="apy">
  //                 est. APY 34%
  //                 <div className="apy-limit">limit $30,000*</div>
  //               </div> */}
  //               <div className="total">TEMPORARILY UNAVAILABLE...</div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>

  //       <div style={{ marginTop: '20px', fontSize: '12px' }}>
  //         * Total stablecoin staking limit sum up (achieving higher levels does
  //         not cancel access to lower-level staking pools)
  //       </div>
  //     </div>
  //   </>
  // );

  return (
    <>
      <div className="staking-pools">
        {tokenData && LPTokenData && stakingPools}
        {/* {isChain(AppChain.Bsc) ? SAStakingPlaceholder : ''}
        {isChain(AppChain.Bsc) ? StablecoinStakingPlaceholder : ''} */}
      </div>

      {/*  TODO: Remove in later stage */}
      <Modal
        show={showInfoModal}
        onHide={() => setShowInfoModal(false)}
        className="experience-info-modal"
        centered
      >
        <Modal.Header closeButton className="align-center">
          <Modal.Title>Attention</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-16">Add text here...</div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StakingPools;
