import { asyncSleep } from 'helpers';

import { TxResult } from '@starterra/starterra-tool-dapp';
import {
  Account,
  Coin,
  Coins,
  CreateTxOptions,
  Fee,
  LCDClient,
  MsgExecuteContract,
  TxInfo,
} from '@terra-money/terra.js';

const terraWaitForTxInfo = async (
  terra: LCDClient,
  txhash: string,
  milliseconds = 4000
): Promise<TxInfo> => {
  let tx: TxInfo;

  do {
    try {
      tx = await terra.tx.txInfo(txhash);

      if (tx) {
        return tx;
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}

    await asyncSleep(milliseconds);
    // eslint-disable-next-line no-constant-condition
  } while (true);
};

export const terraExecuteContract = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  terraConnectedWallet: any,
  terra: LCDClient,
  msg: MsgExecuteContract,
  address: string,
  memo?: string
): Promise<TxInfo> => {
  if (terraConnectedWallet.availablePost) {
    const createTxOptions: CreateTxOptions = {
      msgs: [msg],
      memo,
      gasPrices: new Coins([new Coin('uusd', 0.15)]),
      gasAdjustment: 1.2,
      feeDenoms: ['uusd'],
    };
    const accountInfo: Account = await terra.auth.accountInfo(address);
    const fee: Fee = await terra.tx.estimateFee(
      [
        {
          sequenceNumber: accountInfo.getSequenceNumber(),
          publicKey: accountInfo.getPublicKey(),
        },
      ],
      createTxOptions
    );
    const txResult: TxResult = await terraConnectedWallet.post({
      fee,
      msgs: [msg],
    });
    const txInfo: TxInfo = await terraWaitForTxInfo(
      terra,
      txResult.result.txhash,
      4000
    );

    return txInfo;
  }
};
