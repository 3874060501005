import { apiUrl } from 'helpers';
import { LeaderboardAddress } from 'models';
import { leaderboardActions } from 'store';

import { Action, Dispatch } from '@reduxjs/toolkit';

export const getLeaderboardAddresses = (): ((
  dispatch: Dispatch<Action>
) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const response: Response = await fetch(`${apiUrl}/leaderboard`);
      const leaderboardAddresses: LeaderboardAddress[] = await response.json();

      dispatch(
        leaderboardActions.setLeaderboardAddresses(leaderboardAddresses)
      );
    } catch (e) {
      console.log(e);
    }
  };
};
