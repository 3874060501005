import { State } from 'store';
import { AppState } from 'store/reducers';
import Web3 from 'web3';
import Web3Modal from 'web3modal';

import { createSelector } from '@reduxjs/toolkit';
import { LCDClient } from '@terra-money/terra.js';

const selectApp = (state: State): AppState => state.app;

export const selectWeb3 = createSelector(
  [selectApp],
  (appState: AppState): Web3 => appState.web3
);

export const selectWeb3Modal = createSelector(
  [selectApp],
  (appState: AppState): Web3Modal => appState.web3Modal
);

export const selectWeb3Provider = createSelector(
  [selectApp],
  (appState: AppState) => appState.web3Provider
);

export const selectTerra = createSelector(
  [selectApp],
  (appState: AppState): LCDClient => appState.terra
);

export const selectInitializingFinished = createSelector(
  [selectApp],
  (state: AppState): boolean => state.initializingFinished
);

export const selectConnectedAddress = createSelector(
  [selectApp],
  (state: AppState): string => state.connectedAddress
);

export const selectBalance = createSelector(
  [selectApp],
  (state: AppState): string => state.balance
);

export const selectLPBalance = createSelector(
  [selectApp],
  (state: AppState): string => state.balanceLP
);

export const selectStablecoinBalance = createSelector(
  [selectApp],
  (state: AppState): string => state.balanceStablecoin
);

export const selectTokenPrice = createSelector(
  [selectApp],
  (state: AppState): string => state.tokenPrice
);

export const selectLPTokenPrice = createSelector(
  [selectApp],
  (state: AppState): string => state.LPTokenPrice
);

export const selectStableTokenPrice = createSelector(
  [selectApp],
  (state: AppState): string => state.stableTokenPrice
);

export const selectShowLoader = createSelector(
  [selectApp],
  (state: AppState): boolean => state.showLoader
);

export const selectShowModal = createSelector(
  [selectApp],
  (state: AppState): boolean => state.showModal
);
