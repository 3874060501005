import { apiUrl } from 'helpers';
import { UserExperienceData } from 'models';
import { Dispatch } from 'react';
import { experienceActions } from 'store';

import { Action } from '@reduxjs/toolkit';

export const getUserExperienceData = (
  connectedAddress: string
): ((dispatch: Dispatch<Action>) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const chain = process.env.REACT_APP_CHAIN.toLowerCase();
      // &mock_exp=75
      const userExperienceDataUrl = `${apiUrl}/address-stats?address=${connectedAddress}&chain=${chain}`;
      const response = await fetch(userExperienceDataUrl);
      const userExperienceData: UserExperienceData = await response.json();

      dispatch(experienceActions.setUserExperienceData(userExperienceData));
    } catch (e) {
      console.log(e);
    }
  };
};

export const registerAddress = (
  connectedAddress: string
): ((dispatch: Dispatch<Action>) => void) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const chain = process.env.REACT_APP_CHAIN.toLowerCase();
      const userExperienceDataUrl = `${apiUrl}register-address`;
      const requestOptions: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          address: connectedAddress,
          chain,
        }),
      };
      const response = await fetch(userExperienceDataUrl, requestOptions);
      const userExperienceData: UserExperienceData = await response.json();

      dispatch(experienceActions.setUserExperienceData(userExperienceData));
    } catch (e) {
      console.log(e);
    }
  };
};
