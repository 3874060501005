import BigNumber from 'bignumber.js';
import { AppChain, fromWei } from 'helpers';
import {
  StakingPoolItemBondedTokensData,
  StakingPoolItemData,
  StakingPoolItemStakingData,
  TerraGatewayConfig,
  TerraResConfig,
  TerraResStaker,
  TerraResState,
  TokenData,
} from 'models';
import { Contract } from 'web3-eth-contract';

import { LCDClient } from '@terra-money/terra.js';

export const MAX_UINT256 =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export enum StakingPoolActions {
  Stake = 'stake',
  Unstake = 'unstake',
  Withdraw = 'withdraw',
}

export const getStakingPoolItemData = async (
  stakingPoolItemData: StakingPoolItemData,
  stakingPoolItemsData: StakingPoolItemData[],
  stakingTokenData: TokenData,
  connectedAddress: string,
  terra: LCDClient
): Promise<StakingPoolItemStakingData> => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return await getEvmStakingPoolItemData(
        stakingPoolItemData,
        stakingPoolItemsData,
        stakingTokenData,
        connectedAddress
      );

    case AppChain.Terra:
      return await getTerraStakingPoolItemData(
        stakingPoolItemData,
        stakingPoolItemsData,
        stakingTokenData,
        connectedAddress,
        terra
      );

    default:
      throw new Error('Unsupported Chain');
  }
};

export const getEvmStakingPoolItemData = async (
  stakingPoolItemData: StakingPoolItemData,
  stakingPoolItemsData: StakingPoolItemData[],
  stakingTokenData: TokenData,
  connectedAddress: string
): Promise<StakingPoolItemStakingData> => {
  const decimals = stakingTokenData.decimals;
  const stakingAllowance = await stakingTokenData.tokenContract.methods
    .allowance(
      connectedAddress,
      stakingPoolItemData.stakingPoolItemContract.options.address
    )
    .call();
  const poolInfo: {
    stakingToken: string;
    rewardsToken: string;
    rewardRate: string;
    periodFinish: string;
    unstakingFee: string;
    unbondingPeriod: string;
    maxUbondingsPerAccount: string;
    instantWithdrawalPenalty: string;
    // BELOW ONLY FOR LOCKED
    lockPeriod: string;
    maxUnStakesPerAccount: string;
    stakingLimit?: string;
  } = await stakingPoolItemData.stakingPoolItemContract.methods
    .getPoolInfo()
    .call();
  const currentStats: {
    poolTotalSupply: string;
    poolRewardsBalance: string;
    poolRewardRate: string;
    stakedAmount: string;
    rewardsEarned: string;
    totalInUnbonding: string;
    availableForWithdrawal: string;
    unbondings: { timestamp: string; amount: string }[];
    // BELOW ONLY FOR LOCKED
    stakes: { timestamp: string; amount: string }[];
  } = await stakingPoolItemData.stakingPoolItemContract.methods
    .getCurrentStats(connectedAddress)
    .call();
  let moveBondDestinationAddresses: string[];
  const instantWithdrawalPenalty = (
    +poolInfo.instantWithdrawalPenalty / 100
  ).toString();
  const unbondingPeriod = poolInfo.unbondingPeriod;

  try {
    moveBondDestinationAddresses =
      await stakingPoolItemData.stakingPoolItemContract.methods
        .getMoveStakeGroup()
        .call();
  } catch (e) {
    moveBondDestinationAddresses = undefined;
  }

  let bondedTokensData: StakingPoolItemBondedTokensData[] = [];

  if (unbondingPeriod !== undefined) {
    bondedTokensData = currentStats.unbondings.map(
      ({ amount, timestamp }: StakingPoolItemBondedTokensData) => ({
        amount: fromWei(amount, decimals),
        timestamp: (+timestamp + +unbondingPeriod).toString(),
      })
    );
  } else {
    bondedTokensData = currentStats.stakes.map(
      ({ amount, timestamp }: StakingPoolItemBondedTokensData) => ({
        amount: fromWei(amount, decimals),
        timestamp: (+timestamp + +poolInfo.lockPeriod).toString(),
      })
    );
  }

  moveBondDestinationAddresses = moveBondDestinationAddresses?.filter(
    (contractAddress: string) =>
      contractAddress !==
      stakingPoolItemData?.stakingPoolItemContract?.options?.address
  );
  const moveBondDestinationStakingPoolItems =
    moveBondDestinationAddresses?.reduce(
      (acc: StakingPoolItemData[], contractAddress: string) => {
        const applicablePoolItemData = stakingPoolItemsData.find(
          (poolItemData: StakingPoolItemData) =>
            poolItemData?.stakingPoolItemContract?.options?.address ===
              contractAddress && poolItemData.addressCanStake
        );

        if (applicablePoolItemData) {
          acc.push(applicablePoolItemData);
        }

        return acc;
      },
      []
    );
  const moveBondAvailable =
    (!!+currentStats.stakedAmount || !!+currentStats.totalInUnbonding) &&
    !!moveBondDestinationStakingPoolItems?.length;

  return {
    ...(unbondingPeriod && { unbondingPeriod }),
    stakingAllowance: fromWei(stakingAllowance, decimals),
    // TODO: DECIMALS!!! always take from rewardToken
    rewardTokens: fromWei(currentStats.rewardsEarned, 6),
    stakedTokens: fromWei(currentStats.stakedAmount, decimals),
    totalValueLocked: fromWei(currentStats.poolTotalSupply, decimals),
    availableForWithdrawal: fromWei(
      currentStats.availableForWithdrawal,
      decimals
    ),
    bondedTokensData,
    totalInUnbonding: fromWei(currentStats.totalInUnbonding, decimals),
    ...(instantWithdrawalPenalty && { instantWithdrawalPenalty }),
    // TODO: DECIMALS!!! always take from rewardToken
    rewardRate: fromWei(currentStats.poolRewardRate, 6),
    ...(poolInfo.stakingLimit && {
      maxStake: fromWei(poolInfo.stakingLimit, decimals),
    }),
    moveBondAvailable,
    ...(moveBondDestinationStakingPoolItems?.length && {
      moveBondDestinationStakingPoolItems,
    }),
  };
  // eslint-disable-next-line no-empty
};

export const getTerraStakingPoolItemData = async (
  stakingPoolItemData: StakingPoolItemData,
  stakingPoolItemsData: StakingPoolItemData[],
  stakingTokenData: TokenData,
  connectedAddress: string,
  terra?: LCDClient
): Promise<StakingPoolItemStakingData> => {
  const decimals = stakingTokenData.decimals;
  const resConfig: TerraResConfig = await terra.wasm.contractQuery(
    stakingPoolItemData.contractAddress,
    {
      config: {},
    }
  );
  const resState: TerraResState = await terra.wasm.contractQuery(
    stakingPoolItemData.contractAddress,
    {
      state: {},
    }
  );
  const resStaker: TerraResStaker = await terra.wasm.contractQuery(
    stakingPoolItemData.contractAddress,
    {
      staker_info: {
        staker: connectedAddress,
      },
    }
  );
  const gatewayConfig: TerraGatewayConfig = await terra.wasm.contractQuery(
    resConfig.gateway_address,
    {
      config: {},
    }
  );
  const totalValueLocked: string = fromWei(
    resState.total_bond_amount,
    decimals
  );
  const stakingAllowance: string = fromWei(
    '340282366920938463463374607431768211455',
    decimals
  ); // MAX UINT128
  const rewardTokens: string = fromWei(resStaker.pending_reward, decimals);
  const stakedTokens: string = fromWei(resStaker.bond_amount, decimals);
  let unbondingPeriod: number;
  let lockPeriod: number;
  let instantWithdrawalPenalty: string;
  let availableForWithdrawal: string;
  let totalInUnbonding = '0';
  let bondedTokensData: { amount: string; timestamp: string }[] = [];
  let stakedTokensData: { amount: string; timestamp: string }[] = [];

  if (resConfig.unbond_config && resConfig.unbond_config[1]) {
    unbondingPeriod = resConfig.unbond_config[1].minimum_time;
    let _withdrawalAmountNoFee: BigNumber = new BigNumber(0);
    let _withdrawalAmountWithFee: BigNumber = new BigNumber(0);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resStaker.rewards_per_fee.forEach((item: any) => {
      if (item.percent_lost === 0)
        _withdrawalAmountNoFee = new BigNumber(item.amount);
      else if (item.percent_lost)
        _withdrawalAmountWithFee = new BigNumber(item.amount);
    });

    availableForWithdrawal = fromWei(_withdrawalAmountNoFee, decimals);
    totalInUnbonding = fromWei(
      _withdrawalAmountNoFee.plus(_withdrawalAmountWithFee),
      decimals
    );

    if (resStaker.submitted_to_unbond)
      bondedTokensData = resStaker.submitted_to_unbond.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ({ submission_time, amount }: any) => ({
          amount: fromWei(amount, decimals),
          timestamp: (+submission_time + +unbondingPeriod).toString(),
        })
      );
  } else {
    lockPeriod = resConfig.submit_to_unbond_config.lock_time;
    let _availableForWithdrawal: BigNumber = new BigNumber(0);

    const now = Math.round(Date.now() / 1000);
    if (resStaker.submit_to_unbond_info.bonds)
      stakedTokensData = resStaker.submit_to_unbond_info.bonds.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ({ timestamp, amount }: any) => {
          const unlock_timestamp = +timestamp + +lockPeriod;

          if (now >= unlock_timestamp) {
            _availableForWithdrawal = _availableForWithdrawal.plus(amount);
          }

          return {
            amount: fromWei(amount, decimals),
            timestamp: unlock_timestamp.toString(),
          };
        }
      );

    availableForWithdrawal = fromWei(_availableForWithdrawal, decimals);

    bondedTokensData = stakedTokensData;
  }

  if (resConfig.unbond_config && resConfig.unbond_config[0]) {
    instantWithdrawalPenalty =
      resConfig.unbond_config[0].percentage_loss.toString();
  }

  const currentSchedule =
    resConfig.distribution_schedule[resConfig.distribution_schedule.length - 1];
  const distributionScheduleAmountBN = new BigNumber(currentSchedule.amount);
  const endTime = new BigNumber(currentSchedule.end_time);
  const startTime = new BigNumber(currentSchedule.start_time);
  const rewardRate = fromWei(
    distributionScheduleAmountBN.div(endTime.minus(startTime)),
    decimals
  );
  const maxStake = fromWei(resConfig.max_stake, decimals);
  const moveBondDestinationAddresses: string[] =
    gatewayConfig?.staking_contracts?.filter(
      (contractAddress: string) =>
        contractAddress !== stakingPoolItemData.contractAddress
    );
  const moveBondDestinationStakingPoolItems =
    moveBondDestinationAddresses?.reduce(
      (acc: StakingPoolItemData[], contractAddress: string) => {
        const applicablePoolItemData = stakingPoolItemsData.find(
          (poolItemData: StakingPoolItemData) =>
            poolItemData?.contractAddress === contractAddress &&
            poolItemData.addressCanStake
        );

        if (applicablePoolItemData) {
          acc.push(applicablePoolItemData);
        }

        return acc;
      },
      []
    );
  const moveBondAvailable =
    (!!+stakedTokens || !!+totalInUnbonding) &&
    !!moveBondDestinationStakingPoolItems?.length;

  return {
    stakingAllowance,
    rewardTokens,
    stakedTokens,
    totalValueLocked,
    availableForWithdrawal,
    bondedTokensData,
    totalInUnbonding,
    rewardRate,
    ...(unbondingPeriod && {
      unbondingPeriod: unbondingPeriod.toString(),
    }),
    ...(instantWithdrawalPenalty && { instantWithdrawalPenalty }),
    ...(maxStake && { maxStake }),
    moveBondAvailable,
    ...(moveBondDestinationStakingPoolItems?.length && {
      moveBondDestinationStakingPoolItems,
    }),
  };
};

export const canStake = async (
  connectedAddress: string,
  contract?: Contract,
  contractAddress?: string,
  terra?: LCDClient
): Promise<boolean> => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return await canStakeEvm(contract, connectedAddress);
    case AppChain.Terra:
      return await canStakeTerra(terra, contractAddress, connectedAddress);
  }
};

export const canStakeEvm = async (
  contract: Contract,
  connectedAddress: string
): Promise<boolean> => {
  try {
    const canStake = await contract.methods
      .isWhitelisted(connectedAddress)
      .call();
    return canStake;
  } catch (e) {
    console.log(e);

    return false;
  }
};

export const canStakeTerra = async (
  terra: LCDClient,
  contractAddress: string,
  connectedAddress: string
): Promise<boolean> => {
  try {
    const result: { is_whitelisted: boolean } = await terra.wasm.contractQuery(
      contractAddress,
      {
        is_whitelisted: {
          address: connectedAddress,
        },
      }
    );

    return result.is_whitelisted;
  } catch (e) {
    console.log(e);

    return false;
  }
};
