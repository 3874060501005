import { Contract } from 'web3-eth-contract';

export enum PlaynityTokenName {
  Playnity = 'Playnity',
  LPPlaynity = 'LP-Playnity',
  Stablecoin = 'Stablecoin',
}

export enum TokenSymbol {
  PLY = 'PLY',
  'LPPLY' = 'LP',
  UST = 'aUST',
  BUSD = 'BUSD',
  USDT = 'USDT',
}

export interface TokenData {
  name: PlaynityTokenName;
  symbol: TokenSymbol;
  decimals: number;
  tokenContract?: Contract;
  contractAddress?: string;
}

export interface TokenConfig {
  name: PlaynityTokenName;
  symbol: TokenSymbol;
  decimals: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tokenContractJSON: any;
}
