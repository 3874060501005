import { Contract } from 'web3-eth-contract';

export enum VestingPoolType {
  Team = 'Team/Advisors',
  Presale = 'Presale',
}

export interface VestingPoolVestingData {
  amountAllocated: string;
  amountClaimable: string;
  amountLeft: string;
}

export interface VestingPoolConfig {
  poolType: VestingPoolType;
  vestingContractJSON: any;
}

export interface VestingConfig {
  pools: VestingPoolConfig[];
}

export interface VestingPoolData {
  poolType: VestingPoolType;
  vestingPoolContract?: Contract;
  contractAddress?: string;
}
