/* eslint-disable no-debugger */
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import {
  Message,
  StakingPoolItemClaimTabContent,
  StakingPoolItemHeader,
  StakingPoolItemMoveBondTabContent,
  StakingPoolItemStakeTabContent,
  StakingPoolItemWithdrawTabContent,
} from 'components';
import {
  AppChain,
  getStakingPoolItemData,
  isChain,
  isCorrectValue,
  MAX_UINT256,
  MessageText,
  MessageType,
  postGetReward,
  postMoveBond,
  postStake,
  postUnstake,
  postWithdraw,
  postWithdrawInstant,
  StakingPoolActions,
} from 'helpers';
import {
  PairData,
  StakingPoolItemData,
  StakingPoolItemStakingData,
  StakingPoolType,
  TokenData,
} from 'models';
import React, { useEffect, useReducer } from 'react';
import { Accordion, Card, Nav, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBalance,
  getTokenPrice,
  getTokensInLpPair,
  selectConnectedAddress,
  selectLpPairData,
  selectStableTokenPrice,
  selectTerra,
  selectTokenPrice,
  selectTokensInLpPair,
  selectTotalLp,
} from 'store';

/* eslint-disable react-hooks/rules-of-hooks */
import { useConnectedWallet } from '@starterra/starterra-tool-dapp';
import { LCDClient } from '@terra-money/terra.js';
import { ConnectedWallet } from '@terra-money/wallet-provider';

import {
  initialStakingPoolDetailsState,
  StakingPoolDetailsActions,
  stakingPoolDetailsReducer,
} from '../store/reducer';
import { StakingPoolItemDetails } from './StakingPoolItemDetails';

interface StakingPoolItemProps {
  index: number;
  balance: string;
  stakingPoolItemsData: StakingPoolItemData[];
  stakingPoolItemData: StakingPoolItemData;
  stakingTokenData: TokenData;
  tokenData: TokenData;
  LPTokenData: TokenData;
  stablecoinTokenData: TokenData;
  stakingPoolType: StakingPoolType;
  isActive: boolean;
  moveBondDestinationPoolItemData: StakingPoolItemData;
  onMoveBondFinished: (destinationPoolItemData: StakingPoolItemData) => void;
}

export const StakingPoolItem: React.FC<StakingPoolItemProps> = ({
  index,
  balance,
  stakingPoolItemData,
  stakingPoolItemsData,
  stakingTokenData,
  tokenData,
  LPTokenData,
  stablecoinTokenData,
  stakingPoolType,
  isActive,
  moveBondDestinationPoolItemData,
  onMoveBondFinished,
}) => {
  const [state, dispatch] = useReducer(
    stakingPoolDetailsReducer,
    initialStakingPoolDetailsState
  );
  const dispatchApp = useDispatch();
  const LPPairData: PairData = useSelector(selectLpPairData);
  const connectedAddress: string = useSelector(selectConnectedAddress);
  const tokenPrice: string = useSelector(selectTokenPrice);
  const stableTokenPrice: string = useSelector(selectStableTokenPrice);
  const tokensInLpPair: string = useSelector(selectTokensInLpPair);
  const totalLp: string = useSelector(selectTotalLp);

  // Terra specific fields
  let terra: LCDClient;
  let terraConnectedWallet: ConnectedWallet;

  if (isChain(AppChain.Terra)) {
    terra = useSelector(selectTerra);
    terraConnectedWallet = useConnectedWallet();
  }

  const getData = () => {
    getStakingPoolDetailsData();
    dispatchApp(getBalance(tokenData, connectedAddress, terra));
    dispatchApp(getBalance(LPTokenData, connectedAddress, terra));
    dispatchApp(getBalance(stablecoinTokenData, connectedAddress, terra));
    dispatchApp(
      getTokenPrice(tokenData, stablecoinTokenData, LPPairData, terra)
    );
    dispatchApp(getTokensInLpPair(tokenData, LPTokenData, LPPairData, terra));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const isDestinationStakingPoolItem =
      moveBondDestinationPoolItemData?.poolItemName ===
      stakingPoolItemData?.poolItemName;

    if (isDestinationStakingPoolItem) {
      getData();
    }
  }, [moveBondDestinationPoolItemData]);

  useEffect(() => {
    if (state.loading === false) {
      getData();
    }
  }, [state.loading]);

  const getStakingPoolDetailsData = async () => {
    try {
      const stakingPoolItemStakingData: StakingPoolItemStakingData =
        await getStakingPoolItemData(
          stakingPoolItemData,
          stakingPoolItemsData,
          stakingTokenData,
          connectedAddress,
          terra
        );

      dispatch({
        type: StakingPoolDetailsActions.SetStaking,
        payload: stakingPoolItemStakingData,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onApprove = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: true });
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });

    try {
      await stakingTokenData.tokenContract.methods
        .approve(
          stakingPoolItemData.stakingPoolItemContract.options.address,
          MAX_UINT256
        )
        .send({ from: connectedAddress });

      // TODO: Find better way for refresh
      dispatch({
        type: StakingPoolDetailsActions.SetStakingValue,
        payload: '',
      });
    } catch (err) {
      dispatch({
        type: StakingPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (err as any).message,
      });
    }
    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });
  };

  const onStake = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: true });
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });

    try {
      await postStake(
        stakingPoolItemData,
        stakingTokenData,
        state.stakingValue,
        stakingTokenData.decimals,
        connectedAddress,
        terraConnectedWallet,
        terra
      );
    } catch (err) {
      dispatch({
        type: StakingPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (err as any).message,
      });
    }

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });
    dispatch({ type: StakingPoolDetailsActions.SetStakingValue, payload: '' });
  };

  const onUnstake = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: true });
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });

    try {
      await postUnstake(
        stakingPoolItemData,
        state.unstakingValue,
        stakingTokenData.decimals,
        connectedAddress,
        terraConnectedWallet,
        terra
      );
    } catch (err) {
      dispatch({
        type: StakingPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (err as any).message,
      });
    }

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });
    dispatch({
      type: StakingPoolDetailsActions.SetUnstakingValue,
      payload: '',
    });
  };

  const onWithdraw = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    instant?: boolean
  ) => {
    event.preventDefault();
    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: true });
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });

    try {
      if (instant) {
        await postWithdrawInstant(
          stakingPoolItemData,
          state.staking.totalInUnbonding,
          stakingTokenData.decimals,
          connectedAddress,
          terraConnectedWallet,
          terra
        );
      } else {
        await postWithdraw(
          stakingPoolItemData,
          stakingPoolItemData.lockPeriod
            ? state.unstakingValue
            : state.staking.availableForWithdrawal,
          stakingTokenData.decimals,
          connectedAddress,
          terraConnectedWallet,
          terra,
          state.staking.unbondingPeriod
        );
      }
    } catch (err) {
      dispatch({
        type: StakingPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (err as any).message,
      });
    }

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });
    dispatch({
      type: StakingPoolDetailsActions.SetUnstakingValue,
      payload: '',
    });
  };

  const onMoveBond = async (destinationPoolItemData: StakingPoolItemData) => {
    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: true });
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });

    try {
      await postMoveBond(
        stakingPoolItemData,
        destinationPoolItemData,
        connectedAddress,
        terraConnectedWallet,
        terra
      );

      onMoveBondFinished(destinationPoolItemData);
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    } catch (err) {
      dispatch({
        type: StakingPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (err as any).message,
      });
    }

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });
  };

  const onClaim = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: true });
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });

    try {
      await postGetReward(
        stakingPoolItemData,
        connectedAddress,
        terraConnectedWallet,
        terra
      );
    } catch (err) {
      dispatch({
        type: StakingPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (err as any).message,
      });
    }

    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });
  };

  const onSelectTab = () => {
    dispatch({ type: StakingPoolDetailsActions.SetErrorMessage, payload: '' });
    dispatch({ type: StakingPoolDetailsActions.SetLoading, payload: false });

    // TODO: Fix - fired also on setLoading
    getStakingPoolDetailsData();
  };

  const onStakeValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value;

    if (isCorrectValue(value, stakingTokenData.decimals)) {
      dispatch({
        type: StakingPoolDetailsActions.SetStakingValue,
        payload: value,
      });
    }
  };

  const onUnstakeValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value;

    if (isCorrectValue(value, stakingTokenData.decimals)) {
      dispatch({
        type: StakingPoolDetailsActions.SetUnstakingValue,
        payload: value,
      });
    }
  };

  const onApplyPercentage = (
    percentage: number,
    value: string,
    actionType: string
  ) => {
    const BNValue = new BigNumber(value);
    const roundedNumber = BNValue.times(percentage)
      .div(100)
      .toFixed(stakingTokenData.decimals);

    switch (actionType) {
      case StakingPoolActions.Stake: {
        dispatch({
          type: StakingPoolDetailsActions.SetStakingValue,
          payload: roundedNumber,
        });

        break;
      }

      case StakingPoolActions.Unstake:
      case StakingPoolActions.Withdraw: {
        dispatch({
          type: StakingPoolDetailsActions.SetUnstakingValue,
          payload: roundedNumber,
        });

        break;
      }
    }
  };

  const stakingPoolItemClassNames = classNames(
    'staking-pool-item',
    stakingPoolItemData?.poolItemtype.toLocaleLowerCase().replaceAll(' ', '-'),
    { inactive: !isActive }
  );

  return (
    <div className={stakingPoolItemClassNames}>
      <Card className={classNames({ loading: !state.staking })}>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={index.toString()}
          onClick={() => {
            isActive &&
              state.staking &&
              dispatch({
                type: StakingPoolDetailsActions.SetIsExpanded,
                payload: !state.isExpanded,
              });
          }}
        >
          <StakingPoolItemHeader
            stakingPoolItemData={stakingPoolItemData}
            isActive={isActive}
            staking={state.staking}
            isExpanded={state.isExpanded}
            tokenPrice={tokenPrice}
            stableTokenPrice={stableTokenPrice}
            tokensInLpPair={tokensInLpPair}
            totalLp={totalLp}
            stakingPoolType={stakingPoolType}
            stakingTokenData={stakingTokenData}
          />
        </Accordion.Toggle>

        {isActive && state.staking && (
          <Accordion.Collapse
            eventKey={index.toString()}
            unmountOnExit={true}
            mountOnEnter={true}
          >
            <Card.Body>
              <div className="staking-pool-item-body">
                <StakingPoolItemDetails
                  balance={balance}
                  staking={state.staking}
                  symbol={stakingTokenData.symbol}
                />
                <Tab.Container
                  id="tabs-container"
                  defaultActiveKey="stake"
                  onSelect={onSelectTab}
                >
                  <Nav variant="pills" className="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="stake" disabled={state.loading}>
                        Stake
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="withdraw" disabled={state.loading}>
                        Withdraw
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="claim" disabled={state.loading}>
                        Claim
                      </Nav.Link>
                    </Nav.Item>
                    {state.staking.moveBondAvailable ? (
                      <Nav.Item>
                        <Nav.Link eventKey="movebond" disabled={state.loading}>
                          Move
                        </Nav.Link>
                      </Nav.Item>
                    ) : (
                      ''
                    )}
                  </Nav>

                  <Tab.Content className="tabs">
                    <StakingPoolItemStakeTabContent
                      loading={state.loading}
                      balance={balance}
                      stakingValue={state.stakingValue}
                      staking={state.staking}
                      stakingPoolItemData={stakingPoolItemData}
                      stakingTokenData={stakingTokenData}
                      onStake={(event) => onStake(event)}
                      onApprove={(event) => onApprove(event)}
                      onStakeValueChange={(event) => onStakeValueChange(event)}
                      onApplyPercentage={(percentage, value) =>
                        onApplyPercentage(
                          percentage,
                          value,
                          StakingPoolActions.Stake
                        )
                      }
                    />
                    <StakingPoolItemWithdrawTabContent
                      loading={state.loading}
                      unstakingValue={state.unstakingValue}
                      staking={state.staking}
                      tokenData={stakingTokenData}
                      stakingPoolItemData={stakingPoolItemData}
                      onWithdraw={(event, instant) =>
                        onWithdraw(event, instant)
                      }
                      onUnstake={(event) => onUnstake(event)}
                      onUnstakeValueChange={(event) =>
                        onUnstakeValueChange(event)
                      }
                      onApplyPercentage={(percentage, value, actionType) =>
                        onApplyPercentage(percentage, value, actionType)
                      }
                    />
                    <StakingPoolItemClaimTabContent
                      loading={state.loading}
                      staking={state.staking}
                      onClaim={(event) => onClaim(event)}
                    />
                    {state.staking.moveBondAvailable ? (
                      <StakingPoolItemMoveBondTabContent
                        loading={state.loading}
                        staking={state.staking}
                        connectedAddress={connectedAddress}
                        stakingPoolItemsData={stakingPoolItemsData}
                        stakingTokenData={stakingTokenData}
                        moveBondDestinationStakingPoolItems={
                          state.staking.moveBondDestinationStakingPoolItems
                        }
                        stakingPoolType={stakingPoolType}
                        terra={terra}
                        onMoveBond={(
                          destinationPoolItemData: StakingPoolItemData
                        ) => onMoveBond(destinationPoolItemData)}
                      />
                    ) : (
                      ''
                    )}
                    {state.errorMessage && (
                      <Message
                        descriptionText={
                          state.errorMessage.includes('404')
                            ? `${state.errorMessage} ${MessageText.Fees}`
                            : state.errorMessage
                        }
                        messageType={MessageType.Error}
                      />
                    )}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        )}
      </Card>
    </div>
  );
};
