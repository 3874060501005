import BigNumber from 'bignumber.js';
import { StakingPoolItemHeaderData, StakingPoolType } from 'models';

export const getPoolHeaderData = (
  tokenPrice: string,
  stableTokenPrice: string,
  tokensInLpPair: string,
  totalLP: string,
  totalStaked: string,
  rewardPerSecond: string,
  stakingPoolType: StakingPoolType
): StakingPoolItemHeaderData => {
  switch (stakingPoolType) {
    case StakingPoolType.SA:
      return getPoolHeaderDataForSAStaking(
        tokenPrice,
        totalStaked,
        rewardPerSecond
      );
    case StakingPoolType.LP:
      return getPoolHeaderDataForLPStaking(
        tokenPrice,
        tokensInLpPair,
        totalLP,
        totalStaked,
        rewardPerSecond
      );
    case StakingPoolType.SC:
      return getPoolHeaderDataForSCStaking(
        tokenPrice,
        stableTokenPrice,
        totalStaked,
        rewardPerSecond
      );
  }
};

export const getPoolHeaderDataForLPStaking = (
  tokenPrice: string,
  tokensInLpPair: string,
  totalLP: string,
  totalStaked: string,
  rewardPerSecond: string
): StakingPoolItemHeaderData => {
  const tokenPriceBN = new BigNumber(tokenPrice);
  const tokensInLpPairBN = new BigNumber(tokensInLpPair);
  const totalLPBN = new BigNumber(totalLP);
  const totalStakedBN = new BigNumber(totalStaked);
  const rewardPerSecondBN = new BigNumber(rewardPerSecond);
  const stakeRation = totalLPBN.isGreaterThan(0)
    ? totalStakedBN.div(totalLPBN)
    : new BigNumber(0);
  const tvlBn = stakeRation.times(tokensInLpPairBN).times(2);
  const tvlDolarsBN = tvlBn.times(tokenPriceBN);
  const yieldPerSec = rewardPerSecondBN.div(tvlBn);
  const apr = yieldPerSec.times(3600).times(24).times(365).times(100);
  // const apy1d = aprToApy(apr, 365);
  const apy1m = aprToApy(apr, 12);
  // const apy3m = aprToApy(apr, 4);
  // const apy6m = aprToApy(apr, 2);

  return {
    apy: apy1m.toString(),
    apr: apr.toString(),
    tvlDolars: tvlDolarsBN.toString(10),
  };
};

export const getPoolHeaderDataForSAStaking = (
  tokenPrice: string,
  totalStaked: string,
  rewardPerSecond: string
): StakingPoolItemHeaderData => {
  const tokenPriceBN = new BigNumber(tokenPrice);
  const totalStakedBN = new BigNumber(totalStaked);
  const rewardPerSecondBN = new BigNumber(rewardPerSecond);
  const tvlDolarsBN = totalStakedBN.times(tokenPriceBN);
  const yieldPerSec = rewardPerSecondBN.div(totalStakedBN);
  const apr = yieldPerSec.times(3600).times(24).times(365).times(100);
  // const apy1d = aprToApy(apr, 365);
  const apy1m = aprToApy(apr, 12);
  // const apy3m = aprToApy(apr, 4);
  // const apy6m = aprToApy(apr, 2);

  return {
    apy: apy1m.toString(),
    apr: apr.toString(),
    tvlDolars: tvlDolarsBN.toString(10),
  };
};

export const getPoolHeaderDataForSCStaking = (
  tokenPrice: string,
  stableTokenPrice: string,
  totalStaked: string,
  rewardPerSecond: string
): StakingPoolItemHeaderData => {
  const tokenPriceBN = new BigNumber(tokenPrice);
  const stableTokenPriceBN = new BigNumber(stableTokenPrice);
  const totalStakedBN = new BigNumber(totalStaked); //aust
  const rewardPerSecondBN = new BigNumber(rewardPerSecond); //ply
  const tvlDolarsBN = totalStakedBN.times(stableTokenPriceBN); //$
  const yieldPerSec = rewardPerSecondBN.div(tvlDolarsBN.div(tokenPriceBN));
  const apr = yieldPerSec.times(3600).times(24).times(365).times(100);
  // const apy1d = aprToApy(apr, 365);
  const apy1m = aprToApy(apr, 12);
  // const apy3m = aprToApy(apr, 4);
  // const apy6m = aprToApy(apr, 2);

  return {
    apy: apy1m.toString(),
    apr: apr.toString(),
    tvlDolars: tvlDolarsBN.toString(10),
  };
};

const aprToApy = (apr: BigNumber, periods: number): BigNumber => {
  return new BigNumber(1)
    .plus(apr.div(100).div(periods))
    .pow(periods)
    .minus(1)
    .times(100);
};
