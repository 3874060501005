import { AppChain, getContract, playnityConfig } from 'helpers';
import { TokenConfig, TokenData } from 'models';
import { Dispatch } from 'react';
import { tokenActions } from 'store';
import Web3 from 'web3';

import { Action } from '@reduxjs/toolkit';

export const getTokensContracts = (
  networkId: string,
  web3?: Web3
): ((dispatch: Dispatch<Action>) => void) => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc:
    case AppChain.Ethereum:
      return getEvmTokensContracts(networkId, web3);
    case AppChain.Terra:
      return getTerraTokensContracts(networkId);
  }
};

const getEvmTokensContracts = (
  networkId: string,
  web3: Web3
): ((dispatch: Dispatch<Action>) => void) => {
  return (dispatch: Dispatch<Action>) => {
    try {
      const tokenContracts: TokenData[] = playnityConfig.tokens.map(
        (tokenConfig: TokenConfig) => {
          const tokenContract = getContract(
            web3,
            tokenConfig.tokenContractJSON,
            networkId
          );

          return {
            tokenContract,
            name: tokenConfig.name,
            symbol: tokenConfig.symbol,
            decimals: tokenConfig.decimals,
          };
        }
      );

      dispatch(tokenActions.setTokensData(tokenContracts));
    } catch (e) {
      console.log(e);
    }
  };
};

const getTerraTokensContracts = (
  networkId: string
): ((dispatch: Dispatch<Action>) => void) => {
  return (dispatch: Dispatch<Action>) => {
    try {
      const tokenContracts: TokenData[] = playnityConfig.tokens.map(
        (tokenConfig: TokenConfig) => {
          const contractAddress = (
            tokenConfig.tokenContractJSON.networks as any
          )[networkId]?.address;

          return {
            tokenContract: null,
            name: tokenConfig.name,
            symbol: tokenConfig.symbol,
            decimals: tokenConfig.decimals,
            contractAddress,
          };
        }
      );

      dispatch(tokenActions.setTokensData(tokenContracts));
    } catch (e) {
      console.log(e);
    }
  };
};
