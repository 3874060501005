import badgeClaimed from 'assets/images/experience/badge-claimed.svg';
import badgeLocked from 'assets/images/experience/badge-locked.svg';
import badgeUnclaimed from 'assets/images/experience/badge-unclaimed.svg';
import chest10 from 'assets/images/experience/chest-10.svg';
import chest11 from 'assets/images/experience/chest-11.svg';
import chest12 from 'assets/images/experience/chest-12.svg';
import chest13 from 'assets/images/experience/chest-13.svg';
import chest14 from 'assets/images/experience/chest-14.svg';
import chest15 from 'assets/images/experience/chest-15.svg';
import chest2 from 'assets/images/experience/chest-2.svg';
import chest3 from 'assets/images/experience/chest-3.svg';
import chest4 from 'assets/images/experience/chest-4.svg';
import chest5 from 'assets/images/experience/chest-5.svg';
import chest6 from 'assets/images/experience/chest-6.svg';
import chest7 from 'assets/images/experience/chest-7.svg';
import chest8 from 'assets/images/experience/chest-8.svg';
import chest9 from 'assets/images/experience/chest-9.svg';
import chestLocked10 from 'assets/images/experience/chest-locked-10.svg';
import chestLocked11 from 'assets/images/experience/chest-locked-11.svg';
import chestLocked12 from 'assets/images/experience/chest-locked-12.svg';
import chestLocked13 from 'assets/images/experience/chest-locked-13.svg';
import chestLocked14 from 'assets/images/experience/chest-locked-14.svg';
import chestLocked15 from 'assets/images/experience/chest-locked-15.svg';
import chestLocked2 from 'assets/images/experience/chest-locked-2.svg';
import chestLocked3 from 'assets/images/experience/chest-locked-3.svg';
import chestLocked4 from 'assets/images/experience/chest-locked-4.svg';
import chestLocked5 from 'assets/images/experience/chest-locked-5.svg';
import chestLocked6 from 'assets/images/experience/chest-locked-6.svg';
import chestLocked7 from 'assets/images/experience/chest-locked-7.svg';
import chestLocked8 from 'assets/images/experience/chest-locked-8.svg';
import chestLocked9 from 'assets/images/experience/chest-locked-9.svg';
import chestLocked from 'assets/images/experience/chest-locked.svg';
import chest from 'assets/images/experience/chest.svg';
import { ButtonWithSpinner } from 'components';
import { ExperienceLevel, UserExperienceData } from 'models';
import React, { useState } from 'react';

interface LevelItemProps {
  experienceLevel: ExperienceLevel;
  userExperienceData: UserExperienceData;
  onRegisterClick: () => void;
}

export const LevelItem: React.FC<LevelItemProps> = ({
  experienceLevel,
  userExperienceData,
  onRegisterClick,
}) => {
  const [loadingRegistration, setLoadingRegistration] = useState(false);
  const levelClaimed = userExperienceData.curLevel >= experienceLevel.level;
  const levelUnclaimed = userExperienceData.curExp >= experienceLevel.minXp;
  const levelItemClasses = `level-item level-${experienceLevel.level} ${
    levelClaimed ? 'claimed' : levelUnclaimed ? 'unlocked' : 'locked'
  } `;

  const getLevelChest = (level: number, isClaimed: boolean) => {
    switch (level) {
      case 2: {
        return isClaimed ? chest2 : chestLocked2;
      }

      case 3: {
        return isClaimed ? chest3 : chestLocked3;
      }

      case 4: {
        return isClaimed ? chest4 : chestLocked4;
      }

      case 5: {
        return isClaimed ? chest5 : chestLocked5;
      }

      case 6: {
        return isClaimed ? chest6 : chestLocked6;
      }

      case 7: {
        return isClaimed ? chest7 : chestLocked7;
      }

      case 8: {
        return isClaimed ? chest8 : chestLocked8;
      }

      case 9: {
        return isClaimed ? chest9 : chestLocked9;
      }

      case 10: {
        return isClaimed ? chest10 : chestLocked10;
      }

      case 11: {
        return isClaimed ? chest11 : chestLocked11;
      }

      case 12: {
        return isClaimed ? chest12 : chestLocked12;
      }

      case 13: {
        return isClaimed ? chest13 : chestLocked13;
      }

      case 14: {
        return isClaimed ? chest14 : chestLocked14;
      }

      case 15: {
        return isClaimed ? chest15 : chestLocked15;
      }

      default: {
        return isClaimed ? chest : chestLocked;
      }
    }
  };

  const images = (() => {
    let badge;

    if (levelClaimed) {
      badge = badgeClaimed;
    } else if (levelUnclaimed) {
      badge = badgeUnclaimed;
    } else {
      badge = badgeLocked;
    }

    return {
      badge,
      chest: getLevelChest(experienceLevel.level, levelClaimed),
    };
  })();

  return (
    <div className={levelItemClasses}>
      <div className="level-item-header">
        <div className="level-item-header-text">
          <div className="font-bold uppercase">{experienceLevel.name}</div>
          <div>{experienceLevel.minXp} PEX</div>
        </div>
        <img className="level-item-header-image" src={images.badge} />
      </div>

      <div className="level-item-indicator">{experienceLevel.level}</div>
      <div className="level-item-indicator-cover"></div>

      {experienceLevel.level == 0 ? (
        <div className="level-item-registration">
          <div className="level-item-registration-text">
            <div className="level-item-registration-text-header">Start</div>
            <div className="level-item-registration-text-description">
              {userExperienceData.curLevel == 0 ? (
                <>Join the journey</>
              ) : (
                <>Registered</>
              )}
            </div>
          </div>
          {userExperienceData.curLevel == 0 ? (
            <ButtonWithSpinner
              classes="level-item-registration-button"
              onClick={() => {
                setLoadingRegistration(true);
                onRegisterClick();
              }}
              loading={loadingRegistration}
              disabled={loadingRegistration}
            >
              Click to register
            </ButtonWithSpinner>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div
          className="level-item-description"
          dangerouslySetInnerHTML={{ __html: experienceLevel.description }}
        ></div>
      )}

      <img className="level-item-chest" src={images.chest} />
    </div>
  );
};
