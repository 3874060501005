import logo from 'assets/images/playnity-app.png';
import binanceLogo from 'assets/svg/binance-logo-small.svg';
import ethLogo from 'assets/svg/eth-logo.svg';
import terraLogo from 'assets/svg/terra-logo-small.svg';
import { AppChain } from 'helpers';
import React, { ReactElement } from 'react';

// TODO: Update styles
export const Footer = (): ReactElement => {
  const bscLogoElement = (
    <a href="https://bsc.playnity.io">
      <img
        className="footer-chain-logo"
        src={binanceLogo}
        alt="Bsc chain logo"
      />
    </a>
  );
  const terraLogoElement = (
    <a href="https://terra.playnity.io">
      <img
        className="footer-chain-logo"
        src={terraLogo}
        alt="Terra chain logo"
      />
    </a>
  );
  const ethLogoElement = (
    <a href="https://eth.playnity.io">
      <img className="footer-chain-logo" src={ethLogo} alt="Eth chain logo" />
    </a>
  );
  const renderGoToElement = () => {
    switch (process.env.REACT_APP_CHAIN as AppChain) {
      case AppChain.Bsc:
        return (
          <div className="footer-chain-link">
            Go to
            {terraLogoElement}
            <span className="ml-8">/</span>
            {ethLogoElement}
            <span className="ml-8">app</span>
          </div>
        );
      case AppChain.Terra:
        return (
          <div className="footer-chain-link">
            Go to
            {bscLogoElement}
            <span className="ml-8">/</span>
            {ethLogoElement}
            <span className="ml-8">app</span>
          </div>
        );
      case AppChain.Ethereum:
        return (
          <div className="footer-chain-link link">
            Go to
            {bscLogoElement}
            <span className="ml-8">/</span>
            {terraLogoElement}
            <span className="ml-8">app</span>
          </div>
        );
    }
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 flex-container flex-wrap justify-between align-center footer-row">
            <section className="logo">
              <img className="img" alt="Playnity logo" src={logo} />
            </section>

            <nav className="navigation">
              <ul className="navigation-container">
                <li className="navigation-link">
                  <a href="https://playnity.io/">Home</a>
                </li>
                <li className="navigation-link">
                  <a
                    href="https://playnity.io/uploads/WhitePaper_PlayNity"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Whitepaper
                  </a>
                </li>
                <li className="navigation-link">
                  <a
                    href="https://medium.com/@media_playnity"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Medium articles
                  </a>
                </li>
                <li className="navigation-link">
                  <a
                    href="https://playnity.io/contact/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </nav>

            <section className="social">
              <div className="description gray">Follow us</div>
              <a
                className="social-link"
                href="https://www.facebook.com/playnity/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook white"></i>
              </a>
              <a
                className="social-link"
                href="https://twitter.com/play_nity"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter white"></i>
              </a>
              <a
                className="social-link"
                href="https://discord.com/invite/playnity"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-discord white"></i>
              </a>
              <a
                className="social-link"
                href="https://www.youtube.com/channel/UCGeFauQQyNBfqsopbZU2NMw"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-telegram white"></i>
              </a>
              <a
                className="social-link"
                href="https://t.me/PLAYNITY"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-youtube white"></i>
              </a>
            </section>

            <section className="flex-container flex-wrap align-center justify-between grow-1">
              <div className="copyright">
                Copyright &#169; {new Date().getFullYear()} PLAYNITY
              </div>
              <div className="chain-link">{renderGoToElement()}</div>
            </section>
          </div>
        </div>
      </div>
    </footer>
  );
};
