export enum StakingPoolItemType {
  Locked = 'Locked',
  Bonded = 'Bonded',
}
import { Contract } from 'web3-eth-contract';

export enum StakingPoolType {
  LP = 'LP Staking',
  SA = 'Single Asset Staking',
  SC = 'Stablecoin Staking',
}

export enum StakingPoolName {
  LP = 'LP Staking',
  SA = 'Single Asset Staking',
  PEXSA = 'Single Asset Staking - PEX Bonus',
  PEXSC = 'Stablecoin Staking - PEX Bonus',
}

export enum StakingPoolSubtype {
  PEX = 'PEX',
}

export enum StakingPoolItemName {
  Liquid = 'Liquid',
  LiquidPexBronze = 'Pex Bonus - 🥉 Bronze',
  LiquidPexSilver = 'Pex Bonus - 🥈 Silver',
  LiquidPexGold = 'Pex Bonus - 🥇 Gold',
  LiquidPexDiamond = 'Pex Bonus - 💎 Diamond',
  ThreeMonths = '3 Months Lock',
  SixMonths = '6 Months Lock',
  TwelveMonths = '12 Months Lock',
}

export interface StakingPoolItemBondedTokensData {
  amount: string;
  timestamp: string;
}

export interface StakingPoolItemStakingData {
  stakingAllowance: string;
  rewardTokens: string;
  stakedTokens: string;
  totalValueLocked: string;
  availableForWithdrawal: string;
  bondedTokensData: StakingPoolItemBondedTokensData[];
  totalInUnbonding: string;
  unbondingPeriod?: string;
  instantWithdrawalPenalty?: string;
  rewardRate?: string;
  maxStake?: string;
  moveBondAvailable?: boolean;
  moveBondDestinationStakingPoolItems?: StakingPoolItemData[];
}

export interface StakingConfig {
  pools: StakingPoolConfig[];
}

export type StakingPoolItemLockPeriod = 3 | 6 | 12;

export interface StakingPoolItemConfig {
  poolItemName: StakingPoolItemName;
  poolItemtype: StakingPoolItemType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stakingContractJSON: any;
  lockPeriod?: StakingPoolItemLockPeriod;
  hasWhitelist?: boolean;
}

export interface StakingPoolItemData {
  poolItemName: StakingPoolItemName;
  poolItemtype: StakingPoolItemType;
  stakingPoolItemContract: Contract;
  contractAddress?: string;
  lockPeriod?: 3 | 6 | 12;
  addressCanStake?: boolean;
}

export interface StakingPoolConfig {
  poolType: StakingPoolType;
  poolName: StakingPoolName;
  poolDescription: string;
  poolItems: StakingPoolItemConfig[];
  poolSubtype?: StakingPoolSubtype;
}

export interface StakingPoolData {
  poolType: StakingPoolType;
  poolName: StakingPoolName;
  poolDescription: string;
  poolItems: StakingPoolItemData[];
  poolSubtype: StakingPoolSubtype;
}

export interface StakingPoolItemHeaderData {
  apy: string;
  apr: string;
  tvlDolars: string;
}
