/* eslint-disable @typescript-eslint/no-unused-vars */

import { isProduction } from 'helpers/environment.helper';
import {
  AppConfig,
  PlaynityTokenName,
  StakingPoolConfig,
  StakingPoolItemName,
  StakingPoolItemType,
  StakingPoolName,
  StakingPoolSubtype,
  StakingPoolType,
  TokenSymbol,
  VestingPoolConfig,
} from 'models';

import LPPairContractJSON from './contracts/LPPair.json';
import tokenLPContractJSON from './contracts/LPToken.json';
import SCTokenJSON from './contracts/SCToken.json';
// import stakingLPTwelveMonthsContractJSON from './contracts/StakingLP12Months.json';
import stakingLPThreeMonthsContractJSON from './contracts/StakingLP3Months.json';
// import stakingLPSixMonthsContractJSON from './contracts/StakingLP6Months.json';
import stakingLPLiquidContractJSON from './contracts/StakingLPLiquid.json';
// import stakingSATwelveMonthsContractJSON from './contracts/StakingSA12Months.json';
// import stakingSAThreeMonthsContractJSON from './contracts/StakingSA3Months.json';
// import stakingSASixMonthsContractJSON from './contracts/StakingSA6Months.json';
import stakingSALiquidContractJSON from './contracts/StakingSALiquid.json';
import stakingSABronzeContractJSON from './contracts/StakingSALiquidBronze.json';
import stakingSADiamondContractJSON from './contracts/StakingSALiquidDiamond.json';
import stakingSAGoldContractJSON from './contracts/StakingSALiquidGold.json';
import stakingSASilverContractJSON from './contracts/StakingSALiquidSilver.json';
import stakingSCBronzeContractJSON from './contracts/StakingSCLiquidBronze.json';
import stakingSCDiamondContractJSON from './contracts/StakingSCLiquidDiamond.json';
import stakingSCGoldContractJSON from './contracts/StakingSCLiquidGold.json';
import stakingSCSilverContractJSON from './contracts/StakingSCLiquidSilver.json';
import tokenContractJSON from './contracts/Token.json';

// TODO: Move to config.model / helper ( avoid circular dependency - separate MR)
export enum BscNetworkType {
  Mainnet = '56',
  Testnet = '97',
}

const availableBscNetworks = {
  0: {
    name: 'mainnet',
    chainID: BscNetworkType.Mainnet,
  },
  ...(!isProduction() && {
    1: {
      name: 'testnet',
      chainID: BscNetworkType.Testnet,
    },
  }),
};

const LPStakingPool: StakingPoolConfig = {
  poolType: StakingPoolType.LP,
  poolDescription: 'PLY-BUSD',
  poolName: StakingPoolName.LP,
  poolItems: [
    {
      poolItemName: StakingPoolItemName.Liquid,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingLPLiquidContractJSON,
    },
    {
      poolItemName: StakingPoolItemName.ThreeMonths,
      poolItemtype: StakingPoolItemType.Locked,
      stakingContractJSON: stakingLPThreeMonthsContractJSON,
      lockPeriod: 3,
    },
    // {
    //   poolItemName: StakingPoolItemName.SixMonths,
    //   poolItemtype: StakingPoolItemType.Locked,
    //   stakingContractJSON: stakingLPSixMonthsContractJSON,
    //   lockPeriod: 6,
    // },
    // {
    //   poolItemName: StakingPoolItemName.TwelveMonths,
    //   poolItemtype: StakingPoolItemType.Locked,
    //   stakingContractJSON: stakingLPTwelveMonthsContractJSON,
    //   lockPeriod: 12,
    // },
  ],
};
const SAStakingPool: StakingPoolConfig = {
  poolType: StakingPoolType.SA,
  poolDescription: 'PLY',
  poolName: StakingPoolName.SA,
  poolItems: [
    {
      poolItemName: StakingPoolItemName.Liquid,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSALiquidContractJSON,
    },
  ],
};
const PEXSAStakingPool: StakingPoolConfig = {
  poolType: StakingPoolType.SA,
  poolSubtype: StakingPoolSubtype.PEX,
  poolDescription: 'PLY',
  poolName: StakingPoolName.PEXSA,
  poolItems: [
    {
      poolItemName: StakingPoolItemName.LiquidPexBronze,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSABronzeContractJSON,
      hasWhitelist: true,
    },
    {
      poolItemName: StakingPoolItemName.LiquidPexSilver,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSASilverContractJSON,
      hasWhitelist: true,
    },
    {
      poolItemName: StakingPoolItemName.LiquidPexGold,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSAGoldContractJSON,
      hasWhitelist: true,
    },
    {
      poolItemName: StakingPoolItemName.LiquidPexDiamond,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSADiamondContractJSON,
      hasWhitelist: true,
    },
  ],
};
const PEXSCStakingPool: StakingPoolConfig = {
  poolType: StakingPoolType.SC,
  poolSubtype: StakingPoolSubtype.PEX,
  poolDescription: 'BUSD',
  poolName: StakingPoolName.PEXSC,
  poolItems: [
    {
      poolItemName: StakingPoolItemName.LiquidPexBronze,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSCBronzeContractJSON,
      hasWhitelist: true,
    },
    {
      poolItemName: StakingPoolItemName.LiquidPexSilver,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSCSilverContractJSON,
      hasWhitelist: true,
    },
    {
      poolItemName: StakingPoolItemName.LiquidPexGold,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSCGoldContractJSON,
      hasWhitelist: true,
    },
    {
      poolItemName: StakingPoolItemName.LiquidPexDiamond,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: stakingSCDiamondContractJSON,
      hasWhitelist: true,
    },
  ],
};

const activeStakingPools: StakingPoolConfig[] = [
  LPStakingPool,
  SAStakingPool,
  PEXSAStakingPool,
  PEXSCStakingPool,
];
const activeVestingPools: VestingPoolConfig[] = [];

export const bscConfig: AppConfig = {
  playnityFee: '0.3%',
  maxUnbondingsPerAccount: 3,
  maxLockedStakesPerAccount: 50,
  permittedNetworks: Object.values(availableBscNetworks).map(
    (bscNetwork) => bscNetwork.chainID
  ),
  tokens: [
    {
      name: PlaynityTokenName.Playnity,
      symbol: TokenSymbol.PLY,
      decimals: 6,
      tokenContractJSON: tokenContractJSON,
    },
    {
      name: PlaynityTokenName.LPPlaynity,
      symbol: TokenSymbol.LPPLY,
      decimals: 18,
      tokenContractJSON: tokenLPContractJSON,
    },
    {
      name: PlaynityTokenName.Stablecoin,
      symbol: TokenSymbol.BUSD,
      decimals: 18,
      tokenContractJSON: SCTokenJSON,
    },
  ],
  vesting: {
    pools: activeVestingPools,
  },
  staking: {
    pools: activeStakingPools,
  },
  LPPair: {
    contractJSON: LPPairContractJSON,
  },
  additionalHomeData: {
    tokenAddress: '0x5f39dd1bb6db20f3e792c4489f514794cac6392c',
    tokenChartLink:
      'https://www.dextools.io/app/bsc/pair-explorer/0xb9c375e279ddd8cbd74fabf9cd77d8c0071d05cd',
    tokenLink:
      'https://www.pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x5f39dd1bb6db20f3e792c4489f514794cac6392c',
    lpPairLink:
      'https://www.pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x5f39dd1bb6db20f3e792c4489f514794cac6392c',
  },
};
