import { LeaderboardAddress } from 'models';
import React from 'react';

import { LeaderboardTableRow } from './LeaderboardTableRow';

export interface LeaderboardTableProps {
  leaderboardAddresses: LeaderboardAddress[];
  connectedAddress: string;
}

export const LeaderboardTable: React.FC<LeaderboardTableProps> = ({
  leaderboardAddresses,
  connectedAddress,
}) => {
  const leaderboardTableRows = leaderboardAddresses.map(
    (leaderboardAddress: LeaderboardAddress, index: number) => (
      <LeaderboardTableRow
        leaderboardAddress={leaderboardAddress}
        connectedAddress={connectedAddress}
        key={`leaderboard-table-row-${index}`}
      />
    )
  );

  return (
    <div className="leaderboard-table">
      <div className="leaderboard-table-header">
        <div className="flex-container align-center justify-between gray">
          <div className="rank">Rank</div>
          <div className="address">Wallet Address</div>
          <div className="value">Contributed USD</div>
        </div>
      </div>

      {leaderboardTableRows.length ? (
        <div className="leaderboard-table-rows">{leaderboardTableRows}</div>
      ) : (
        <div className="leaderboard-table-no-results">
          No addresses found...
        </div>
      )}
    </div>
  );
};
