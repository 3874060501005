import { ExperienceLevel } from 'models';

export const experienceLevels: ExperienceLevel[] = [
  {
    level: 0,
    minXp: 0,
    name: 'Registration',
    description: 'Registration',
  },
  {
    level: 1,
    minXp: 1,
    name: 'Beginner',
    description: 'Just the beggining.<div>No rewards yet</div>',
  },
  {
    level: 2,
    minXp: 50,
    name: 'Trainee',
    description:
      '$PLY staking multiplier <div><span class="font-small level-item-description-icon">🥉</span> Bronze</div>',
  },
  {
    level: 3,
    minXp: 100,
    name: 'Amateur',
    description:
      'P2E games earnings activation via stablecoin staking - <span class="font-small level-item-description-icon">🥉</span> Bronze',
  },
  {
    level: 4,
    minXp: 200,
    name: 'Hustler',
    description:
      '30% Discount on public <span class="font-small level-item-description-icon">👦🏻</span> Genesis NFT sale',
  },
  {
    level: 5,
    minXp: 400,
    name: 'Hotshot',
    description:
      '$PLY staking multiplier <div><span class="font-small level-item-description-icon">🥈</span> Silver</div>',
  },
  {
    level: 6,
    minXp: 700,
    name: 'Expert',
    description:
      '1 guaranteed WL presale slot for <span class="font-small level-item-description-icon">👦🏻</span> Genesis NFT with 40% Discount',
  },
  {
    level: 7,
    minXp: 1100,
    name: 'Veteran',
    description:
      'Stablecoin staking <div><span class="font-small level-item-description-icon">🥈</span> Silver</div>',
  },
  {
    level: 8,
    minXp: 1500,
    name: 'Semi-Pro',
    description:
      '$PLY staking multiplier <div><span class="font-small level-item-description-icon">🥇</span> Gold</div>',
  },
  {
    level: 9,
    minXp: 2000,
    name: 'Pro',
    description:
      '1 presale ticket for Guild’s Lands area <span class="font-small level-item-description-icon">🏕️</span>',
  },
  {
    level: 10,
    minXp: 3100,
    name: 'Master',
    description:
      'Stablecoin staking <div><span class="font-small level-item-description-icon">🥇</span> Gold</div>',
  },
  {
    level: 11,
    minXp: 4300,
    name: 'Champion',
    description:
      '3 guaranteed WL presale slots for <span class="font-small level-item-description-icon">👦🏻</span> Genesis NFT with 40% Discount',
  },
  {
    level: 12,
    minXp: 5600,
    name: 'Superstar',
    description:
      '2 presale tickets for Guild’s Lands area <span class="font-small level-item-description-icon">🏕️</span>',
  },
  {
    level: 13,
    minXp: 7000,
    name: 'Hero',
    description:
      '$PLY staking multiplier<div><span class="font-small level-item-description-icon">💎</span> Diamond</div>',
  },
  {
    level: 14,
    minXp: 8500,
    name: 'Immortal',
    description:
      'Stablecoin staking <div><span class="font-small level-item-description-icon">💎</span> Diamond</div>',
  },
  {
    level: 15,
    minXp: 10000,
    name: 'Legend',
    description:
      '<span class="font-small level-item-description-icon">🎖️</span> Hall of fame listed and individual NFT',
  },
];
