import experienceBadge from 'assets/images/experience/header-image.svg';
import {
  DataItem,
  ExperienceInfoModal,
  LevelItems,
  SectionDetails,
} from 'components';
import { experienceLevels } from 'helpers';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserExperienceData,
  registerAddress,
  selectConnectedAddress,
  selectUserExperienceData,
} from 'store';

import { ExperienceCircularProgressBar } from './';

export const Experience: React.FC = () => {
  const dispatch = useDispatch();
  const userExperienceData = useSelector(selectUserExperienceData);
  const connectedAddress = useSelector(selectConnectedAddress);
  // const [loading, setLoading] = useState(false);
  // const [errorMessage, setErrorMessage] = useState('');
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (connectedAddress) {
      dispatch(getUserExperienceData(connectedAddress));
    }
  }, [connectedAddress]);

  useEffect(() => {
    if (userExperienceData) {
      const levelStartingXp = experienceLevels.find(
        (el) => el.level === userExperienceData.curLevel
      )?.minXp;
      const levelEndingXp = experienceLevels.find(
        (el) => el.level === userExperienceData.curLevel + 1
      )?.minXp;

      if (levelStartingXp && levelEndingXp) {
        const percentage =
          ((userExperienceData.curExp - levelStartingXp) /
            (levelEndingXp - levelStartingXp)) *
          100;

        setTimeout(() => setPercentage(percentage), 100);
      }
    }
  }, [userExperienceData]);

  const onRegister = () => {
    try {
      dispatch(registerAddress(connectedAddress));
    } catch (e) {
      console.log(e);
    }
  };

  // const onLevelUp = async (
  //   event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  // ) => {
  //   event.preventDefault();
  //   setLoading(true);
  //   setErrorMessage('');

  //   try {
  //     // TODO
  //     // await postLevelUp();
  //     setErrorMessage('Method not implemented');
  //   } catch (err) {
  //     setErrorMessage(err as any);
  //   }

  //   setLoading(true);
  // };
  const currentExperience = () => {
    if (!userExperienceData) {
      return;
    }

    return (
      <>
        {userExperienceData.curExp} PEX{' '}
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-added-exp">
              {userExperienceData.addedExp} PEX added in the last snapshot
            </Tooltip>
          }
        >
          <i className="far fa-question-circle pointer"></i>
        </OverlayTrigger>
      </>
    );
  };

  return (
    <div className="experience" style={{ fontFamily: 'Roboto' }}>
      <SectionDetails title="Experience"></SectionDetails>

      {userExperienceData ? (
        <>
          <div className="experience-header">
            <div className="experience-progress">
              <div className="gray font-small text-center mb-8">
                Your experience level
              </div>
              <div className="experience-progress-container">
                <ExperienceCircularProgressBar
                  percentage={percentage}
                  userExperienceData={userExperienceData}
                />
              </div>
            </div>

            <div className="experience-info">
              <div className="experience-info-item">
                <img
                  className="experience-info-item-image"
                  src={experienceBadge}
                />
                <DataItem
                  label="Current experience:"
                  classes="experience-info-item-data"
                >
                  {currentExperience()}
                </DataItem>
              </div>
              <div className="experience-info-item">
                <img
                  className="experience-info-item-image"
                  src={experienceBadge}
                />
                <DataItem
                  label="Experience to next level:"
                  data={`${
                    userExperienceData.expToNextLevel?.toString() ?? '-'
                  } PEX`}
                  classes="experience-info-item-data"
                />
              </div>
            </div>
          </div>

          <div className="experience-description text-center">
            Pex points (PlayNity Experience points) can be gathered everyday by
            your possessed LPs, $PLY, leaderboard place, hodler time bonus and
            occasionally announced tasks.
          </div>

          <div className="experience-description text-center">
            <div className="orange">Coming soon! New PEX system features:</div>
            <div className="mt-16">
              Soon in Q2, loyal PEX collectors will have the possibility to take
              part in the{' '}
              <a
                className="link"
                href="https://playnity.io/lands/"
                target="_blank"
                rel="noreferrer"
              >
                PlayNity Land Game
              </a>{' '}
              token private presale phase relatively to gathered PEX points
              (also any PEX points over 10 000 PEX will count, so it&apos;s
              worth to continue gathering even after reaching the Legendary
              level).
            </div>
            <div className="mt-8">
              Additional game token airdrop and exclusive premium access to the{' '}
              <a
                className="link"
                href="https://playnity.io/academy/"
                target="_blank"
                rel="noreferrer"
              >
                PlayNity Academy
              </a>{' '}
              is also planned for those with Daily Multiplier x8. All PEX points
              gathered earlier, now and in the coming time will be taken into
              account.
            </div>
          </div>

          <div className="experience-links">
            <ExperienceInfoModal />
            <a
              className="link"
              href="https://www.youtube.com/watch?v=fUPqlFrLTsQ"
              rel="noopener noreferrer"
              target="_blank"
            >
              Video tutorial
            </a>
          </div>

          {/* <div className="text-center">
          <ButtonWithSpinner
            classes="btn-style-two"
            onClick={onLevelUp}
            disabled={!userExperienceData.canLevelUp}
            loading={loading}
          >
            Level Up
          </ButtonWithSpinner>
          {errorMessage && (
            <Message
              descriptionText={errorMessage}
              messageType={MessageType.Error}
            />
          )}
          </div> */}
          <LevelItems
            userExperienceData={userExperienceData}
            onRegisterClick={() => onRegister()}
          />
        </>
      ) : (
        <div className="experience-loader">
          <Spinner animation="border" role="status" variant="light" size="sm" />
        </div>
      )}
    </div>
  );
};
