import logo from 'assets/images/playnity-app.png';
import { copyToClipboard, playnityConfig } from 'helpers';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export const Home = (): ReactElement => {
  return (
    <div className="home">
      <div className="home-header">
        <div className="home-header-logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="home-header-text">
          <div>Welcome to Playnity App</div>
          <div>Choose your action</div>
        </div>
        <div className="home-header-buttons">
          <a
            className="btn btn-style-two"
            href={playnityConfig.additionalHomeData.tokenLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Buy PLY Tokens
          </a>
          <Link to="/staking" className="btn btn-style-two">
            Go to staking
          </Link>
        </div>
        <div className="home-token-info">
          <span className="home-token-info-header gray">
            PLY Token address:
          </span>
          <span className="home-token-info-address">
            {playnityConfig.additionalHomeData.tokenAddress}
          </span>
          <i
            className="far fa-copy copy-icon home-token-info-copy-icon"
            onClick={() =>
              copyToClipboard(playnityConfig.additionalHomeData.tokenAddress)
            }
          ></i>
        </div>
      </div>
    </div>
  );
};
