/* eslint-disable @typescript-eslint/no-explicit-any */
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import { AbiItem } from 'web3-utils';

export const getContract = (
  web3: Web3,
  contractJSON: Record<string, unknown>,
  networkId: string
): Contract => {
  return new web3.eth.Contract(
    contractJSON.abi as AbiItem[],
    (contractJSON.networks as any)[networkId]?.address
  );
};

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const fromWei = (
  value: BigNumber | BigNumber.Value,
  decimals: number
): string => {
  return new BigNumber(value || 0)
    .div(new BigNumber(10).pow(decimals))
    .toString(10);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toWei = (amount: any, decimals: number): string => {
  const BNAmount = new BigNumber(amount);
  const multiplier = new BigNumber(10).pow(decimals);

  return BNAmount.times(multiplier).toString(10);
};

// export const toDecimal = (value = '0', decimals: number) =>
//   new BigNumber(value).decimalPlaces(decimals, BigNumber.ROUND_DOWN).toString();
